import React from 'react'
import '../style.css'

import Fade from 'react-reveal/Fade';

const AboutUs = () => {
  return (
    <section id='about-us'>
        <img src="/img/about-us.png" alt="" />
        <div className="about-us">
            <h5 className='title'>About Us</h5>
            <Fade>
            <p>The International College of Management, Sydney (ICMS) is a higher education institution with campuses in manly and Sydney's business district. Founded in 1996, ICMS is a leader in career-focused, quality education and offers bachelor's and master's degrees to Australian and international students. ICMS degrees are at the same Australian Qualifications Framework (AQF) level as University degrees. ICMS degrees are recognised by various industry partners over the world</p>
            </Fade>
        </div>  
    </section>
  )
}

export default AboutUs