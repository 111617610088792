import '../style.css'
const WhySection = () => {
  return (
    <section id='why-section'>
        <div className="container">
            <h3 className='why-title'>Kenapa harus kuliah di ICMS ?</h3>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-book-open"></i>
                    <h5 className='title'>Study</h5>
                    <p>Ranked #1 in Australia for Hospitality, Skills Development</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-user-graduate"></i>
                    <h5 className='title'>Alumni</h5>
                    <p>100% of ICMS Graduates Have a Job</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-briefcase"></i>
                    <h5 className='title'>Work</h5>
                    <p>Top 8 for overall employment in Australian Higher Education</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-handshake-simple"></i>
                    <h5 className='title'>Collaboration</h5>
                    <p>Over 1000 Industry Partners</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhySection