import React from 'react'
import '../style.css'

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <>
    <section id='footer'>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="contact">
                        <h6><span>NIEC INDONESIA</span> Jl. Buluh Indah No 56 Denpasar</h6>
                        <h6><span>PHONE:</span> <a href="tel:+6289527440126">+62 895-2744-0126</a></h6>
                        <h6><span>EMAIL:</span> <a href="mailto:bizdev@niecindonesia.com">bizdev@niecindonesia.com</a></h6>
                        
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="social">
                        <h5>SOCIAL:</h5>
                        <a href="https://www.facebook.com/niec.indonesia" target="_blank"><i className="fa-brands fa-facebook"></i></a>
                        <a href="https://www.instagram.com/niec_indonesia" target="_blank"><i className="fa-brands fa-square-instagram"></i></a>
                        <a href="https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ" target="_blank"><i className="fa-brands fa-youtube"></i></a>
                        <a href="https://twitter.com/NIEC_Indonesia" target="_blank"><i className="fa-brands fa-square-twitter"></i></a>
                    </div>
                </div>
            </div>
                <div className="logo">
                    <a href="https://www.icms.edu.au/" target="_blank" rel="noopener noreferrer">
                        <img src="/img/logo.png" alt="" />
                    </a>
                </div>
        </div>
    </section>
    <div className="footer">
        <div className="container">
            NIEC INDONESIA &copy; {year}. All Rights Reserved
        </div>
    </div>
    </>
  )
}

export default Footer