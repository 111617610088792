import React from 'react'
import '../style.css'

const Header = () => {
  return (
  <>
    <section id='header'>
        <div className="hero">
          <div className="container">
            <h1>Study at ICMS</h1>
          </div>
        </div>
    </section>
  </>
  )
}

export default Header