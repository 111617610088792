import React from 'react'
import { Parallax, Background } from 'react-parallax';
import '../style.css'
const Consultation = () => {
  return (
    <Parallax strength={200} bgImage='/img/consultation.png'>
        <section id="consultation">
            <div className="container">
            <div className="pdf-download">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>ICMS Study <br /> Abroad Program</h3>
                        <p>Kuliah di ICMS Sydney dan Internship dengan pendapatan sampai 440 Juta</p>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                    <a href="/doc/ICMSStudyAbroadProgram_NIECIndonesia.pdf" target="_blank" rel="noopener noreferrer" download>
                        <button className='btn'><img src="/img/icon/download_button.png" alt="" width={15}/> Download Document</button>
                    </a>
                    </div>
                </div>
            </div>
             <div className="row justify-content-between">
                 <div className="col-lg-8">
                     <div className="content1">
                         <h3>Free consultation with exceptional quality</h3>
                     </div>
                 </div>
                 <div className="col-lg-4 my-auto">
                     <div className="content2">
                         <a href="https://www.icms.edu.au/contact/" target="_blank">
                             {/* <button className='btn'>Get your Consultation</button> */}
                             <a href="https://wa.me/6289527440126"><button class="btn">Konsultasikan Sekarang</button></a>
                         </a>
                     </div>
                 </div>
             </div>
        </div>
        </section>
    </Parallax>
  )
}

export default Consultation